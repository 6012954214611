import axios from "axios";

export default {
  store(data) {
    return axios.post(`/api/ftp-users`, data)
  },
  delete(id) {
    return axios.delete(`/api/ftp-users/${id}`)
  }
}
