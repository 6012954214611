import axios from "axios";

export default {
  update(id, data) {
    return axios.put(`/api/publishers/${id}`, data)
  },
  store(data) {
    return axios.post(`/api/publishers`, data)
  },
  delete(id) {
    return axios.delete(`/api/publishers/${id}`)
  },
  list() {
    return axios.get('/api/publishers/list')
  }
}
