<template>
  <v-app>
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="users"
        :page.sync="page"
        :server-items-length="meta.total"
        :items-per-page="meta.per_page"
        :loading="loading"
        hide-default-footer
        class="elevation-1"
      >
        <template v-slot:top>
          <state-indicate ref="indicate" v-slot:default="{isLoading}">
            <v-toolbar
              flat
            >
              <v-toolbar-title>FTP Users List</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="editedIndex = state.creating"
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New FTP User
                  </v-btn>
                </template>
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                          >
                            <ValidationProvider name="Publisher" vid="publishers" rules="integer"
                                                v-slot="{ errors, valid }">
                              <v-autocomplete
                                :items="publishers"
                                item-text="name"
                                item-value="id"
                                v-model="editedItem.publishers"
                                :error-messages="errors"
                                :success="valid"
                                label="Publisher"
                                clearable
                                multiple
                              ></v-autocomplete>
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"

                          >
                            <ValidationProvider name="Username" vid="username" rules="required|min:3|max:30"
                                                v-slot="{ errors, valid }">
                              <v-text-field
                                :counter="30"
                                v-model="editedItem.username"
                                :error-messages="errors"
                                :success="valid"
                                label="Username"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            <ValidationProvider name="Password" vid="password" rules="required|min:8|max:30"
                                                v-slot="{ errors, valid }">
                              <v-text-field
                                v-model="editedItem.password"
                                :counter="30"
                                :error-messages="errors"
                                label="Password"
                              ></v-text-field>
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="close"
                        :disabled="isLoading"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="save"
                        :disabled="invalid"
                        :loading="isLoading"
                      >
                        {{ editedIndex > state.creating ? 'Save' : 'Create' }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </ValidationObserver>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" :disabled="isLoading" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" :loading="isLoading" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </state-indicate>
        </template>
        <template v-slot:item.publishers="{ item }">
           <div v-for="(publisher, index) in item.publishers" :key="index">
             {{publisher.name}}
           </div>
        </template>
        <template v-slot:item.password="{item, index}">
          <v-icon small
                  class="mr-4"
                  @click="toggleDisplayPassword(index)">mdi-eye-outline</v-icon>
          <span v-if="item.showPassword">{{item.password}}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="meta.last_page"
        ></v-pagination>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import FtpUser from "@/models/FtpUser";
import FtpUsersApi from "@/api/ftp-users";
import StateIndicate from "@/components/utils/StateIndicate";
import PublishersApi from "@/api/publishers";

const STATE_EDITING = 1
const STATE_CREATING = -1

export default {
  name: "Crud",
  components: {
    StateIndicate
  },
  data: function () {
    return {
      publishers: [],
      users: [],
      meta: {},
      page: 1,
      dialog: false,
      dialogDelete: false,
      loading: false,
      editedIndex: STATE_CREATING,
      editedItem: {
        id: null,
        username: '',
        password: '',
        publishers: '',
      },
    }
  },
  computed: {
    headers() {
      return [
        {text: 'Username', value: 'username'},
        {text: 'Password', value: 'password'},
        {text: 'Publisher', value: 'publishers'},
        {text: 'Created at', value: 'created_at'},
        {text: 'Actions', value: 'actions', sortable: false},
      ]
    },
    formTitle() {
      return this.editedIndex === this.state.creating ? 'New FTP User' : 'Edit FTP User'
    },
    state() {
      return {creating: STATE_CREATING, editing: STATE_EDITING}
    }
  },
  watch: {
    async page() {
      this.fetchUsers()
    },
    dialog(val) {
      if (val && this.editedIndex === STATE_CREATING) {
        this.editedItem = {...this.defaultItem}
      }
      if (!val) {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = STATE_CREATING;
        this.$nextTick(() => {
          this.$refs.observer.reset()
        })
      }
    }
  },
  methods: {
    toggleDisplayPassword(index) {
      const user = this.users[index]
      user.showPassword = !user.showPassword
      this.$set(this.users, index, user)
    },
    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      this.$refs.indicate.isLoading = true
      try {
        await FtpUsersApi.delete(this.editedItem.id)
        this.closeDelete()
        this.fetchUsers()
      } catch (e) {}
      this.$refs.indicate.isLoading = false
    },
    fetchUsers() {
      this.loading = true;
      return FtpUser.page(this.page).get()
        .then(ApiResponse => {
          this.users = ApiResponse.getData();
          this.meta = ApiResponse.getMeta();
          this.loading = false;
        });
    },
    fetchPublishers() {
      return PublishersApi.list().then(ApiResponse => {
        this.publishers = ApiResponse.getData();
      });
    },
    close() {
      this.dialog = false
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = STATE_CREATING
      })
    },
    async save() {
      if (await this.$refs.observer.validate()) {
        this.$refs.indicate.isLoading = true;
        try {
          if (this.editedIndex === STATE_CREATING) {
            await FtpUsersApi.store(this.editedItem)
            await this.fetchUsers()
            this.close()
          }
        } catch (ApiResponse) {
          if (ApiResponse.hasValidationErrors()) {
            this.$refs.observer.setErrors(ApiResponse.getValidationErrors());
          } else {
            alert('Error while for creating Customer');
          }
        }
        this.$refs.indicate.isLoading = false;
      }
    },
  },
  created() {
    this.defaultItem = {...this.editedItem}
    this.fetchUsers()
    this.fetchPublishers()
  }
}
</script>

<style scoped>

</style>
